.root {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.back {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
}

.title {
  margin-bottom: 36px;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.374px;
}

.text {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 36px;
}

.sign {
  font-size: 16px;
  line-height: 18px;
}

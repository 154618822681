.container {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 16px;
}

.icon {
  padding: 10px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    302.01deg,
    rgba(217, 32, 32, 0.22) -14.62%,
    rgba(217, 32, 32, 0) 113.75%
  );
  border-radius: 47px;
  margin-right: 16px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 2px;
}

.subtitle {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  opacity: 0.5;
}

.content {
  display: flex;
  margin-bottom: 16px;
}

.city_select {
  height: 100%;
  padding: 0 16px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 6;
}

.city_toolbar {
  display: flex;
  align-items: center;
}

.back {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
}

.input {
  height: 44px !important;
  box-sizing: border-box !important;
  padding: 16px 12px !important;
  background: #f5f4f3 !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000 !important;
  outline: none !important;
}

.cities {
  height: calc(100% - 56px);
  overflow: auto;
}

.city {
  padding: 14px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #1c5879;
  cursor: pointer;
}

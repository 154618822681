.verification {
  --ReactInputVerificationCode-itemWidth: 44px;
  --ReactInputVerificationCode-itemHeight: 44px;
  margin-bottom: 16px;
}

.verification .ReactInputVerificationCode__item {
  background: #f5f4f3;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  box-shadow: none;
}

.verification .is-active {
  box-shadow: inset 0 0 0 1px #ccc;
}

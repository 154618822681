.root {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding-bottom: 120px;
}

.main {
  padding: 0 16px;
  background: linear-gradient(180deg, #fff 0%, #f5f4f0 100%);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.back {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
}

.title {
  margin: 4px 0;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
}

.filter {
  margin: 16px 0;
}

.filter_title {
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
}

.city_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 16px 12px;
  background: #f5f4f3;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  cursor: pointer;
}

.empty_city {
  color: #807f7e !important;
}

.clear {
  padding: 6px;
  cursor: pointer;
}

.airports {
  display: flex !important;
  gap: 6px;
  flex-wrap: wrap;
  min-height: 54px !important;
  box-sizing: border-box !important;
  padding: 16px 12px !important;
  background: #f5f4f3 !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #807f7e !important;
  outline: none !important;
  white-space: normal !important;
  overflow: visible !important;
}

.test fieldset {
  border: none !important;
}

.selected {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.apply {
  position: fixed;
  left: 16px;
  right: 16px;
  bottom: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.apply > div {
  width: 100%;
}

.button {
  flex: 3;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  background: linear-gradient(302.01deg, #fab215 -14.62%, #fab215 113.75%);
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
}

.button_reset {
  flex: 1;
  background: inherit;
}

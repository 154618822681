.root {
  padding: 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.date_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.date {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #808080;
}

.lounge {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}

.airport {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 8px;
}

.count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d7b816;
}

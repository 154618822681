.root {
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 0 16px;
}

.back {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.374px;
  margin-bottom: 32px;
}

.button {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  background: linear-gradient(302.01deg, #fab215 -14.62%, #fab215 113.75%);
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
}

.button:disabled {
  opacity: 0.5;
  color: #fff !important;
}

.button_transparent {
  margin-top: 16px;
  height: 48px;
  color: #1c5879 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.button_inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.button_text {
  text-align: left;
  text-transform: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.button_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button_icon {
  color: #1c5879;
}

.button_arrow {
  height: 18px !important;
  color: #b8c1cc;
}

.row {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.input {
  width: 100%;
  height: 44px;
  padding: 0 12px;
  background: #f5f4f3;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  font-size: 16px;
  line-height: 44px;
  color: #000;
  cursor: pointer;
}

.input_disabled {
  cursor: default;
}

.logo {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
}

.text {
  padding: 0 55px;
  position: absolute;
  top: 240px;
  left: 0;
  right: 0;
  font-weight: 700;
  font-size: 25px;
  line-height: 46px;
  text-align: center;
  color: #fff;
}

.text_apeal {
  top: 440px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
}

.welcomeWrapper {
  position: absolute;
  top: 240px;
  left: 0;
  right: 0;
  padding: 0 55px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.buttons {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 60px;
}

.tin_id {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 4px;
  background: #ffdd2d !important;
  border-radius: 50% !important;
}

.tin_id_badge {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 50%;
  max-height: 101px;
  min-width: 302px;
  height: fit-content;
}

.tin_id_icon {
  width: 100%;
  height: 101px;
}

.tin_id:hover {
  background: #fcc521;
}

.divider {
  width: 100%;
  color: #bbb;
}

.divider::before,
.divider::after {
  border-color: #bbb !important;
}

.login {
  width: 100%;
  height: 44px;
  background: linear-gradient(302.01deg, #fab215 -14.62%, #fab215 113.75%) !important;
  border-radius: 12px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #fff !important;
  text-transform: none !important;
}

@media screen and (max-height: 850px) {
  .text {
    padding: 0 35px;
    top: 240px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #fff;
  }

  .text_apeal {
    top: 400px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    padding: 0 25px;
  }

  .buttons {
    gap: 18px;
  }

  .tin_id_badge {
    height: 70px;
    max-height: 70px;
  }

  .tin_id_icon {
    max-height: 70px;
    height: 70px;
    max-width: 200px;
  }
}

@media screen and (max-height: 760px) {
  .text {
    padding: 0 35px;
    top: 240px;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    color: #fff;
  }

  .text_apeal {
    top: 340px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    padding: 0 25px;
  }

  .buttons {
    gap: 12px;
    bottom: 30px;
  }

  .tin_id_badge {
    height: 60px;
    max-height: 60px;
  }

  .tin_id_icon {
    max-height: 60px;
    height: 60px;
    max-width: 200px;
  }
}

.timer {
  margin-top: 10px;
  padding: 6px 0;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #b8aa97;
  cursor: pointer;
}

.timer_active {
  color: #000;
}

.verification {
  display: flex;
  justify-content: center;
}

.root {
  position: relative;
  height: 100%;
  overflow: auto;
}

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paidMark {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1ba049;
  display: flex;
  align-items: center;
}

.paidMark svg {
  margin-right: 7px;
}

.unPaidMark {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #d92020;
  display: flex;
  align-items: center;
}

.mark {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.qr {
  position: sticky;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin: -75px 16px 8px;
}

.item {
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
}

.value {
  padding: 12px 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}

.link a {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #005e83;
  text-decoration: none;
}

.link svg {
  margin-left: 4px;
  color: #005e83;
}

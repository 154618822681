.container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.floating {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px 8px;
  margin: 0 -8px;
}

.main {
  display: flex;
  align-items: center;
}

.imageContainer {
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  border: 0.5px solid rgba(128, 128, 128, 0.2);
  border-radius: 38px;
  margin-right: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusDot {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 28px;
  top: 28px;
  background: #1ba049;
  border: 2px solid #fdfdfd;
  border-radius: 12px;
}

.details {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.meta {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  gap: 4px;
}

.meta div {
  opacity: 0.5;
  display: flex;
  align-items: center;
}

.meta svg {
  margin-right: 4px;
}

.meta span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.2;
}

.status {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}

.price {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-family: var(--font-gothic);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.arrow {
  fill: #b8c1cc !important;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.emptyContainer {
  margin-top: 50px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emptyImage {
  width: 72px;
  height: 72px;
  background: linear-gradient(
    302.01deg,
    rgba(250, 178, 21, 0.22) -14.62%,
    rgba(250, 178, 21, 0) 113.75%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 47px;
  margin-bottom: 16px;
}

.emptyTitle {
  font-family: var(--font-gothic);
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-bottom: 12px;
}

.emptySubtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #857b6d;
  margin-bottom: 32px;
}

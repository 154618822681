.root {
}

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px 32px;
  background: #fff;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.06);
  border-radius: 32px 32px 0 0;
}

.title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.374px;
}

.button_inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.button_text {
  text-align: left;
  text-transform: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.button_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 15px;
}

.button_icon {
  margin-right: 20px;
  color: #1c5879;
}

.button_arrow {
  height: 18px;
  color: #b8c1cc;
}

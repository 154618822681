.input {
  height: 44px !important;
  box-sizing: border-box !important;
  padding: 16px 12px !important;
  background: #f5f4f3 !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000 !important;
  outline: none !important;
  margin-bottom: 16px;
}

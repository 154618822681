.root {
  padding: 0 16px;
}

.icon_root {
  border-radius: 50%;
  box-shadow: 0 4px 10px #efefef;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

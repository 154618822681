.root {
  position: relative;
  padding: 16px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.root:not(:last-child) {
  margin-bottom: 16px;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.374px;
  color: #000;
  text-decoration: none;
}

.title::before {
  position: absolute;
  content: '';
  inset: 0;
}

.params {
  margin-top: 20px;
}

.param {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.param:not(:last-child) {
  margin-bottom: 6px;
}

.param_name {
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
}

.param_value {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.main_root {
  height: 60px !important;
}

.root {
  color: #666 !important;
  padding-bottom: 10px;
  padding-top: 8px;
}

.selected {
  font-size: 0.75rem !important;
  color: #00719d !important;
}

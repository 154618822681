.price {
  display: flex;
  align-items: center;
}

.price img {
  margin-right: 10px;
}

.priceGradient {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  background: linear-gradient(90deg, #f101ed 0%, #627fe1 47.4%, #01d6da 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

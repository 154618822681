.service_item {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.service_item:not(:last-child) {
  margin-bottom: 8px;
}

.card {
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.top {
  padding-bottom: 16px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
}

.top img {
  max-height: 20px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.374px;
  color: #000000;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: black;
}

.badge {
  margin-top: 12px;
  padding: 4px 8px;
  background: #f2f2f2;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  width: fit-content;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
  margin-top: 9.5px;
}

.button_inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.button_text {
  text-align: left;
  text-transform: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.button_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 15px;
}

.button_icon {
  color: #1c5879;
}

.button_arrow {
  height: 18px;
  color: #b8c1cc;
}

.button_language_container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.button_language {
  font-size: 14px;
  line-height: 22px;
  color: #b8c1cc;
  text-transform: capitalize;
}

.dialog_content {
  width: 250px;
  padding: 15px !important;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.carousel .control-dots {
  margin: 0 !important;
  bottom: -16px !important;
  line-height: 1;
}

.carousel .control-dots .dot.selected {
  background: #007675 !important;
  width: 40px !important;
}

.carousel .control-dots .dot {
  width: 20px !important;
  height: 3px !important;
  background: #999 !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
}

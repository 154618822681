.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: 0.3s;
}

.modal {
  position: absolute;
  z-index: 100001;
  width: 100%;
  padding: 16px 32px 32px;
  background: #fff;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.06);
  border-radius: 32px 32px 0 0;
  bottom: 0;
  transition: 0.3s;
}

.title {
  font-family: var(--font-gothic);
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.374px;
}

.content {
  padding: 16px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  padding: 16px;
  background: linear-gradient(180deg, #fff 0%, #f5f4f0 100%);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  position: relative;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.actions {
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
  gap: 16px;
}

.title {
  font-family: var(--font-gothic);
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
}

.titleSmall {
  font-family: var(--font-gothic);
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  color: #000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 5px;
  white-space: nowrap;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  margin-bottom: 30px;
}

.title {
  font-family: var(--font-gothic);
  font-weight: 700;
  font-size: 20px;
}

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  background: #fff;
  position: relative;
}

.link::before {
  position: absolute;
  content: '';
  inset: 0;
  z-index: 1;
}

.image_container {
  width: 100%;
  position: relative;
}

.image img {
  width: 100%;
  height: 250px;
  border-radius: 16px;
  object-fit: cover;
}

.thumbnail {
  width: 100%;
  height: 250px;
  border-radius: 16px;
  background: #d9d9d9;
}

.work_time {
  position: absolute;
  bottom: 16px;
  left: 8px;
  padding: 4px 6px;
  background: #1c5879;
  border-radius: 6px;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}

.lounge {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0 4px;
  color: #000;
}

.airport {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: #808080;
}

.terminal_row {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 14px;
  color: #808080;
}

.container {
  position: absolute;
  z-index: 1;
  background-color: white;
  left: 16px;
  right: 16px;
  top: 25px;

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;

  opacity: 1;
  transform: translateX(0px) scale(1);
  transition: 0.3s;
}

.invisible {
  opacity: 0;
  transform: translateX(24px) scale(0.95);
}

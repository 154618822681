.root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 16px;
  background: linear-gradient(180deg, #ffffff 0%, #f5f4f0 100%);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  height: 32px;
  margin-bottom: 30px;
}

.back {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.374px;
  margin-bottom: 32px;
}

.chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.outlined {
  background: #f5f4f3 !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: #000 !important;
}

.filled {
  background: #1c5879 !important;
  color: #fff !important;
}

.button {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  background: linear-gradient(302.01deg, #fab215 -14.62%, #fab215 113.75%);
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
}

.container {
  padding: 0 !important;
  position: relative;
  height: calc(100% - 56px);
  overflow: auto !important;
}

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  height: 46px;
  background: #f5f4f3;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-bottom: 16px;
}

.container::before {
  content: '+';
  position: absolute;
  font-size: 16px !important;
  line-height: 20px !important;
  left: 48px;
  top: 12px;
  color: #000;
  z-index: 2;
}

.input {
  padding-left: 58px !important;
  height: 44px !important;
  width: 100% !important;
  border: none !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000;
}

.dropdown {
  border-radius: 12px 0 0 12px !important;
  border: none !important;
}

/* Inter & Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto:wght@400;500;700;900&display=swap');
/* Century Gothic font */
@import url('./static/fonts/CenturyGothic/stylesheet.css');

:root {
  --font-roboto: Roboto, Inter, -apple-system, 'Segoe UI', Arial, sans-serif;
  --font-gothic: 'Century Gothic', Inter, -apple-system, 'Segoe UI', sans-serif;
  --font-inter: Inter, -apple-system, 'Segoe UI', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-roboto);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  padding: 16px 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.changeVoucher {
  padding: 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
  color: #1c5879;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  justify-content: space-between;
}

.changeVoucher svg {
  fill: #b8c1cc;
  width: 16px;
  height: 24px;
}

.danger {
  padding: 0;
  height: 48px;
  margin: -8px 0;
  border-top: none;
  color: #cb3636;
}

.visitorsCounter {
  padding: 0 16px 16px;
}

.visitorsCounterLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
  margin-bottom: 8px;
}

.footer {
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #857b6d;
}

.action {
  padding: 16px;
}

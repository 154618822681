.inner {
  height: 100%;
  background: #fff;
}

.main {
  padding: 0 16px;
  background: linear-gradient(180deg, #ffffff 0%, #f5f4f0 100%);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.edit_link {
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  color: #1c5879;
  text-decoration: none;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.374px;
  margin-bottom: 32px;
}

.name {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
    margin-bottom: 30px;
}

.email {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}

.phones {
  margin-bottom: 15px;
}

.phones_title {
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
}

.phones_value {
  margin-top: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.button_inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.button_text {
  text-align: left;
  text-transform: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.button_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 15px;
}

.button_icon {
  color: #1c5879;
}

.button_arrow {
  height: 18px;
  color: #b8c1cc;
}

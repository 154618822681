.root {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.container {
  padding: 16px;
}

.sectionTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}

.sectionTitle:not(:first-child) {
  margin-top: 24px;
}

.sectionContent {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.root {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.alert {
  padding: 16px;
  background: #ffeccf;
  border-radius: 12px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}

.back {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  text-decoration: none;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.374px;
  margin-bottom: 32px;
}

.button {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  background: linear-gradient(302.01deg, #fab215 -14.62%, #fab215 113.75%);
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
}

.button:disabled {
  opacity: 0.5;
  color: #fff !important;
}

.group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  cursor: pointer;
}

.group_text {
  font-size: 16px;
  line-height: 20px;
}

.group_text div:not(:last-child) {
  margin-bottom: 8px;
}

.group_arrow {
  height: 18px !important;
  color: #b8c1cc;
}

.button_transparent {
  height: 48px;
  color: #1c5879 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.button_inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.button_text {
  text-align: left;
  text-transform: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.button_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button_icon {
  color: #1c5879;
}

.button_arrow {
  height: 18px !important;
  color: #b8c1cc;
}

.two_column {
  display: flex;
  gap: 6px;
}

.accordion {
  border-top: none;
  box-shadow: none !important;
}

.accordion::before {
  background-color: transparent !important;
}

.accordion_arrow {
  color: #b8c1cc;
}

.accordion_detail {
  padding: 0 !important;
}

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
  margin-bottom: 8px;
}

.value {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 8px;
}

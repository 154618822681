.root {
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
  margin-bottom: 8px;
}

.inputRequired {
  color: #d92020;
  margin-left: 3px;
}
.root {
  padding: 0 16px;
}

.back {
  position: fixed !important;
  top: 16px !important;
  padding-left: 15px !important;
  max-width: 40px !important;
  background: #fff !important;
  z-index: 2 !important;
}

.back:hover {
  background: #fff;
}

.image_container {
  width: calc(100% + 32px);
  position: relative;
  margin: 0 -16px;
}

.image img {
  width: 100%;
  object-fit: cover;
}

.thumbnail {
  width: 100%;
  height: 250px;
  background: #d9d9d9;
}

.lounge {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin: 30px 0 6px;
  color: #000;
}

.airport {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 2px;
  color: #808080;
}

.location_worktime {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  margin: 14px 0 24px;
  border-top: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

.location_worktime_item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.maintenance {
  color: red;
}

.services {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: solid 1px #ededed;
}

.title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.service_item {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.service_item:not(:last-child) {
  margin-bottom: 8px;
}

.terms {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: solid 1px #ededed;
}

.terms_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.desc {
  padding-bottom: 20px;
}

.actionButton {
  position: sticky;
  bottom: 10px;
  margin-bottom: 10px;
  z-index: 2;
}

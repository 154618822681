.extra {
  margin-top: 16px;
  padding: 0 16px;
}

.filter_title {
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
}

.services {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  height: 195px;
  overflow: hidden;
}

.services_all {
  height: auto;
  overflow: visible;
}

.outlined {
  background: #f5f4f3 !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: #000 !important;
}

.filled {
  background: #1c5879 !important;
  border: solid 1px rgba(0, 0, 0, 0.12) !important;
  color: #fff !important;
}

.services_btn {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px 0;
  font-size: 16px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
}

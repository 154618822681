.controls {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: #f5f4f3;
  border-radius: 12px;
}

.buttonDisabled svg {
  fill: #807f7e;
}

.count {
  width: 48px;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin: 0 8px;
}

.container {
  width: 100%;
  height: 220px;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background: black;
  position: relative;
  padding: 16px;
  cursor: pointer;
}

.tinkoffGradient {
  background: linear-gradient(111.76deg, #27272b 37.06%, #41414d 61.99%, #27272b 94.56%);
}

.defaultGradient {
  background: conic-gradient(
    from 235.2deg at 277.48% -406.76%,
    #000000 -6.11deg,
    #005e83 331.65deg,
    #000000 353.89deg,
    #005e83 691.65deg
  );
}

.details {
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: white;
}

.detailsLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 12px;
}

.detailsValue {
  /* font-family: 'Tinkoff Sans'; */
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.374px;
  color: #ffffff;
  margin-top: 2px;
}

.gwpLogo {
  position: absolute;
  right: 16px;
  bottom: 16px;
  height: 56px;
}

.branding {
  position: absolute;
  top: 0;
  left: 0;
}

.branding h5 {
  font-family: var(--font-roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
  color: white;
  margin: 0;
}

.defaultBranding {
  font-family: var(--font-gothic);
  font-weight: 900;
  font-size: 38px;
  line-height: 46px;
  color: #ffffff;
  top: 4px;
  left: 14px;
}

.tinkoffBranding {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tinkoffBranding h5 {
  margin-top: 6px;
  display: flex;
  align-self: start;
  margin-left: 16px;
}

.tinkoffBranding img {
  width: 90%;
}

.root {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.back {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 17px;
  line-height: 20px;
  color: #1c5879;
  cursor: pointer;
}

.title {
  margin-bottom: 36px;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.374px;
}

.input_title {
  font-size: 14px;
  line-height: 18px;
  color: #857b6d;
  margin-bottom: 8px;
}

.input {
  height: 44px !important;
  box-sizing: border-box !important;
  padding: 16px 12px !important;
  background: #f5f4f3 !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000 !important;
  outline: none !important;
  margin-bottom: 16px;
}

.textarea {
  height: auto !important;
}

.button {
  margin-bottom: 7px !important;
  background: linear-gradient(302.01deg, #fab215 -14.62%, #fab215 113.75%);
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
}

.button:disabled {
  opacity: 0.5;
  color: #fff !important;
}

.container {
  border-top: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  box-shadow: none !important;
}

.container::before {
  background-color: transparent !important;
}

.container > div:first-child {
  min-height: 58px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  display: flex;
  align-items: center;
}

.counter {
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #000000;
  opacity: 0.4;
  margin-left: 8px;
}

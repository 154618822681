.main {
  padding: 0 16px 16px;
  background: linear-gradient(180deg, #ffffff 0%, #f5f4f0 100%);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.374px;
}

.history_link {
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  color: #1c5879;
  cursor: pointer;
  text-decoration: none;
}

.identifier {
  margin-bottom: 30px;
}

.identifier_title {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.identifier_value {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.accordion {
  border-top: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  box-shadow: none !important;
}

.bank_name {
  padding: 10px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.tintedCircleButton {
  margin-left: 16px;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

.cardsColumn {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 16px;
  position: relative;
}

.cardsColumn > div {
  z-index: 1;
  transition: all 0.4s;
  position: absolute;
  width: calc(100% - 32px);
}

.activityContainer {
  opacity: 0;
  padding-bottom: 20px;
  z-index: 0 !important;
  transition: 0.3s !important;
}

.topCard {
  top: 0 !important;
}

.topCard .productInfoButton {
  opacity: 1;
  transform: translateY(16px) scale(1);
}

.hiddenCard {
  top: 100vh !important;
}

.productInfoButton {
  opacity: 0;
  transform: translateY(-10px) scale(0.9);
  transition: 0.3s;
}

.circleButton {
  position: relative;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  cursor: pointer;
}

.iconTinted {
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  cursor: pointer;
}

.defaultButton svg .circleButton svg {
  fill: #1c5879;
}

.floating {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
}

.dot {
  position: absolute;
  width: 6px;
  height: 6px;
  left: 18px;
  top: 4px;

  background: #fab215;
  border: 2px solid #fdfdfd;
  border-radius: 6px;
  box-sizing: content-box;
}

.defaultButton {
  width: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  color: #1c5879;
  text-decoration: none;
  cursor: pointer;
}
